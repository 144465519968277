.App {
  font-family: sans-serif;
  text-align: center;
}

.title {
  font-family: sans-serif;
  border-color: black;
  border-width: medium;
}

.container {
  background-color: rgba(194, 194, 206, 0.767);
  padding: 10px;
  margin: 10px;
}

.invalid {
  color: red;
  border-color: red;
}

.desktop {
  display: block;
  /* inline, inline-block */
}

.mobile {
  display: none;
}

@media(max-width: 480px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
    /* ou inline, inline-block */
  }
}